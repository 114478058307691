import React from 'react'
import { SEO } from '../components/seo'

import { PostList } from '../components/post-list'

const Posts = () => {
  return (
    <div>
      <SEO title="Posts" description="Some are useful" />
      <PostList />
    </div>
   
  )
}

export default Posts